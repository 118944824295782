$(document).ready(function() {

	// Store variables
	
	var accordion_head = $('.menu_left > li > a'),
		accordion_body = $('.menu_left li > .sub-menu');

	// Open the current/first tab on load
	var current = $('.menu_left > li.active > a');
	if(current.length > 0)
		current.next().show();
		//current.next().slideDown('fast');
	//else
		//accordion_head.first().addClass('active').next().slideDown('fast');

	// Click function

	accordion_head.on('click', function(event) {

		// Disable header links
		if($(this).next('ul').length > 0)
			event.preventDefault();

		// Show and hide the tabs on click
		if ($(this).attr('class') != 'active') {
            accordion_head.find('.arrow i').removeClass('fa-chevron-down');
            accordion_head.find('.arrow i').addClass('fa-chevron-right');

            $(this).find('.arrow i').addClass('fa-chevron-down');

			accordion_body.slideUp('fast');
			$(this).next().stop(true,true).slideToggle('fast');
			accordion_head.removeClass('active');
			accordion_head.parent().removeClass('selected');
			$(this).addClass('active');
			$(this).parent().addClass('selected');
		}

	});

    $('#toggleMenu').on('click', function() {
        if ($(this).hasClass('closed')) {
            $(this).removeClass('closed');
            $('#menu_left_wrapper').css('left', '0');
            $('#toggleMenu').css('left', '220px');
            $('#content_wrapper').css('padding-left', '220px');
        } else {
            $(this).addClass('closed');
            $('#menu_left_wrapper').css('left', '-222px');
            $('#toggleMenu').css('left', '0');
            $('#content_wrapper').css('padding-left', '0');
        }
    });
});